import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle-coloc"
export default class extends Controller {
  static targets = ["form", "checked"]

  static values = {
    open: Boolean
  }

  connect() {
    if (this.openValue) {
      this.checkedTarget.checked = true;
      this.showColoc()
    }
  }

  showColoc() {
    this.formTarget.classList.toggle("d-none")
  }
}
