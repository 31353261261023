import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="alert"
export default class extends Controller {
  static targets = ["close"]

  connect() {
    if (this.element.classList.contains('show')) {
      const flash = this.closeTarget
      setTimeout(function() { flash.click() }, 5000, flash);
    }
  }
}
