import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="apply-form"
export default class extends Controller {
  static targets = ["section", "previous", "next", "submit"]

  connect() {
    this.#updateButtonStatus()
  }

  next() {
    const actives = this.sectionTargets.filter(element => element.classList.contains("glf-active"))
    
    
    actives.at(0).classList.remove("glf-active");
    actives.at(0).nextElementSibling.classList.add("glf-active");
    
    actives.at(1).classList.remove("glf-active");
    actives.at(1).nextElementSibling.classList.add("glf-active");
    this.#updateButtonStatus()
  }

  previous() {
    const actives = this.sectionTargets.filter(element => element.classList.contains("glf-active"))
    
    actives.at(0).classList.remove("glf-active");
    actives.at(0).previousElementSibling.classList.add("glf-active");
    
    actives.at(1).classList.remove("glf-active");
    actives.at(1).previousElementSibling.classList.add("glf-active");
    this.#updateButtonStatus()
  }

  #updateButtonStatus() {
    const active = this.sectionTargets.find(element => element.classList.contains("glf-active"))

    if (active.classList.contains('apply-one')) {
      this.previousTarget.classList.add("disabled")
    } else if (active.classList.contains('apply-four')) {
      this.nextTarget.classList.add("disabled")
      this.submitTarget.classList.remove("d-none")
    } else {
      this.nextTarget.classList.remove("disabled")
      this.previousTarget.classList.remove("disabled")
    }
  }
}
