// app/javascript/controllers/flatpickr_controller.js
import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";
import { fr } from "flatpickr/dist/l10n/fr.js";


export default class extends Controller {
  connect() {
    new flatpickr(this.element, {
      locale: 'fr',
      enableTime: false,
      altInput: true,
      altFormat: "j F, Y",
      dateFormat: "Y-m-d",
    });
  }
}