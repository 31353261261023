import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle-input-visibility"
export default class extends Controller {
  static targets = ["status", "toggledInput", "toggledIncome", "monthly", "annual"]

  connect() {
    this.toggleVisibility()
  }

  toggleVisibility() {
    if (['Marié(e)', 'employé', 'Locataire'].includes(this.statusTarget.value)) {
      this.toggledInputTarget.classList.remove('d-none')
      this.toggledIncomeTarget.classList.add('d-none')
    } else if (['indépendant', 'retraité'].includes(this.statusTarget.value)) {
      this.toggledInputTarget.classList.add('d-none')
      this.toggledIncomeTarget.classList.remove('d-none')
    } else {
      this.toggledIncomeTarget.classList.add('d-none')
      this.toggledInputTarget.classList.add('d-none')
    }
  }

  fill(event) {
    if (this.monthlyTarget === event.currentTarget) {
      this.annualTarget.lastChild.value = this.monthlyTarget.lastChild.value
    } else {
      this.monthlyTarget.lastChild.value = this.annualTarget.lastChild.value
    }
  }
}
