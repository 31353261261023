import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sidenav"
export default class extends Controller {
  static targets = ["overlay"]

  updateSidenav() {
    if (this.overlayTarget.style.width === "100%") {
      this.overlayTarget.style.width = "0%";
    } else {
      this.overlayTarget.style.width = "100%";    
    }
  }
}
